import { useStoreActions, useStoreState } from 'easy-peasy';
import Image from '../../presentational/Image';
import Button from '../Button';
import Input from '../../presentational/Input';
import Gap from '../../presentational/Gap';
import Switch from '../../presentational/Switch';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import styled from 'styled-components/native';
import Card from '../../presentational/Card';
import { pxToDp, remToDp, vhToDp } from '../../../utilities/general';

const ColSwitch = styled(Col)`
  padding-left: ${remToDp(1.5)}px;
`;

function EditTenantForm({ buttons, navigation, ...props }) {
  const {
    tenant: { isEditable, input },
    document,
    auth: { user },
    status: { placeholder },
    messages: { unreadCount },
  } = useStoreState((state) => state);

  const { setTenantInputAction } = useStoreActions((actions) => actions);

  return (
    <Card>
      <Row {...props}>
        <Col xs={12} lg={6}>
          <Col>
            <Input
              placeholder='Type in tenant name'
              label='Name'
              editable={isEditable}
              value={input.name}
              onChangeText={(name) => setTenantInputAction({ name })}
              required
            />
          </Col>

          <Col>
            <Input
              placeholder='Type in billing reference'
              label='Billing Reference'
              editable={isEditable}
              value={input.billingReference}
              onChangeText={(billingReference) =>
                setTenantInputAction({ billingReference })
              }
            />
          </Col>
          <Col>
            <Input
              placeholder='Type in Rhinestahl Contact'
              label='Rhinestahl Contact'
              editable={isEditable}
              value={input.rhinestahlEmailContact}
              onChangeText={(rhinestahlEmailContact) =>
                setTenantInputAction({ rhinestahlEmailContact })
              }
            />
          </Col>
          <Col>
            <Input
              placeholder='Type in Quality Control Email Contact'
              label='Quality Control Email Contact'
              editable={isEditable}
              value={input.qualitycontrolEmailContact}
              onChangeText={(qualitycontrolEmailContact) =>
                setTenantInputAction({ qualitycontrolEmailContact })
              }
            />
          </Col>
          <Row>
            <Col xs={12} md={6}>
              <Input
                placeholder='Type in address 1'
                label='Address 1'
                editable={isEditable}
                value={input.address1}
                onChangeText={(address1) => setTenantInputAction({ address1 })}
              />
            </Col>
            <Col xs={12} md={6}>
              <Input
                placeholder='Type in address 2'
                label='Address 2'
                editable={isEditable}
                value={input.address2}
                onChangeText={(address2) => setTenantInputAction({ address2 })}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} xl={4}>
              <Input
                placeholder='Type in city'
                label='City'
                editable={isEditable}
                value={input.city}
                onChangeText={(city) => setTenantInputAction({ city })}
              />
            </Col>
            <Col xs={12} xl={4}>
              <Input
                placeholder='Type in state / province'
                label='State / Province'
                editable={isEditable}
                value={input.stateProvince}
                onChangeText={(stateProvince) =>
                  setTenantInputAction({ stateProvince })
                }
              />
            </Col>
            <Col xs={12} xl={4}>
              <Input
                placeholder='Type in Zip / Postal'
                label='Zip / Postal'
                editable={isEditable}
                value={input.zipPostal}
                onChangeText={(zipPostal) =>
                  setTenantInputAction({ zipPostal })
                }
              />
            </Col>

            <ColSwitch>
              <Switch
                label='Active'
                value={input.active}
                onValueChange={(active) => setTenantInputAction({ active })}
                disabled={!isEditable}
                color='#2089dc'
              />
            </ColSwitch>
          </Row>
        </Col>

        <Col xs={12} lg={4}>
          <Image
            style={{
              marginBottom: pxToDp(15),
              border: '1px solid black',
            }}
            height={vhToDp(25)}
            uri={document.body !== '' ? document.body : placeholder.body}
          />
          <Button.UploadTenantLogo />
        </Col>

        <Col xs={12} lg={2}>
          {user.role == 'admin' ? (
            <>
              <Button.GoToUsers navigation={navigation} disabled={!input.id} />
              <Gap />
            </>
          ) : user.role == 'customer admin' ? (
            <>
              <Button.GoToUsers navigation={navigation} disabled={!input.id} />
              <Gap />
            </>
          ) : user.role == 'Customer Admin' ? (
            <>
              <Button.GoToUsers navigation={navigation} disabled={!input.id} />
              <Gap />
            </>
          ) : null}
          <Button.GoToTools navigation={navigation} disabled={!input.id} />
          {user.role == 'admin' ? (
            <>
              <Gap />

              <Button.GoToToolStatus
                navigation={navigation}
                disabled={!input.id}
              />
              <Gap />

              <Button.GoToOEMStatus
                navigation={navigation}
                disabled={!input.id}
              />

              <Gap />
              <Button.GoToToolCategory
                navigation={navigation}
                disabled={!input.id}
              />
              <Gap />
              <Button.GoToMessages
                navigation={navigation}
                disabled={!input.id}
                unreadCount={unreadCount}
              ></Button.GoToMessages>
              <Gap />
              <Button.GoToTransfers
                navigation={navigation}
                disabled={!input.id}
              ></Button.GoToTransfers>
              <Gap />
              <Button.GoToReporting
                navigation={navigation}
                disabled={!input.id}
              ></Button.GoToReporting>
              <Gap />
              <Button.GoToReadiness
                navigation={navigation}
                disabled={!input.id}
              ></Button.GoToReadiness>
              <Gap />
              <Button.GoToLocations
                navigation={navigation}
                disabled={!input.id}
              />
              <Gap />
              <Button.GoToStorageLocations
                navigation={navigation}
                disabled={!input.id}
              />
              <Gap />
              <Button.GoToLabels navigation={navigation} disabled={!input.id} />
            </>
          ) : user.role == 'customer admin' ? (
            <>
              <Gap />

              <Button.GoToToolStatus
                navigation={navigation}
                disabled={!input.id}
              />
              <Gap />

              <Button.GoToOEMStatus
                navigation={navigation}
                disabled={!input.id}
              />

              <Gap />
              <Button.GoToToolCategory
                navigation={navigation}
                disabled={!input.id}
              />
              <Gap />
              <Button.GoToMessages
                navigation={navigation}
                disabled={!input.id}
                unreadCount={unreadCount}
              ></Button.GoToMessages>
              <Gap />
              <Button.GoToTransfers
                navigation={navigation}
                disabled={!input.id}
              ></Button.GoToTransfers>
              <Gap />
              <Button.GoToReporting
                navigation={navigation}
                disabled={!input.id}
              ></Button.GoToReporting>
              <Gap />
              <Button.GoToReadiness
                navigation={navigation}
                disabled={!input.id}
              ></Button.GoToReadiness>
              <Gap />
              <Button.GoToLocations
                navigation={navigation}
                disabled={!input.id}
              />
              <Gap />
              <Button.GoToStorageLocations
                navigation={navigation}
                disabled={!input.id}
              />
              <Gap />
              <Button.GoToLabels navigation={navigation} disabled={!input.id} />
            </>
          ) : user.role == 'Customer Admin' ? (
            <>
              <Gap />

              <Button.GoToToolStatus
                navigation={navigation}
                disabled={!input.id}
              />
              <Gap />

              <Button.GoToOEMStatus
                navigation={navigation}
                disabled={!input.id}
              />

              <Gap />
              <Button.GoToToolCategory
                navigation={navigation}
                disabled={!input.id}
              />
              <Gap />
              <Button.GoToMessages
                navigation={navigation}
                disabled={!input.id}
                unreadCount={unreadCount}
              ></Button.GoToMessages>
              <Gap />
              <Button.GoToTransfers
                navigation={navigation}
                disabled={!input.id}
              ></Button.GoToTransfers>
              <Gap />
              <Button.GoToReporting
                navigation={navigation}
                disabled={!input.id}
              ></Button.GoToReporting>
              <Gap />
              <Button.GoToReadiness
                navigation={navigation}
                disabled={!input.id}
              ></Button.GoToReadiness>
              <Gap />
              <Button.GoToLocations
                navigation={navigation}
                disabled={!input.id}
              />
              <Gap />
              <Button.GoToStorageLocations
                navigation={navigation}
                disabled={!input.id}
              />
              <Gap />
              <Button.GoToLabels navigation={navigation} disabled={!input.id} />
            </>
          ) : null}
          {user.role == 'admin' && (
            <>
              <Gap />
              <Button.GoToRoles navigation={navigation} disabled={!input.id} />
            </>
          )}
          <Gap />
          <Button.GoToWorkPackages
            navigation={navigation}
            disabled={!input.id}
          />
          {user.role == 'admin' ? (
            <>
              <Gap />
              <Button.GoToInspection
                navigation={navigation}
                disabled={!input.id}
              />
            </>
          ) : user.role == 'customer admin' ? (
            <>
              <Gap />
              <Button.GoToInspection
                navigation={navigation}
                disabled={!input.id}
              />
            </>
          ) : user.role == 'Customer Admin' ? (
            <>
              <Gap />
              <Button.GoToInspection
                navigation={navigation}
                disabled={!input.id}
              />
            </>
          ) : null}
          <Gap />
          <Button.GoToBrokenTools
            navigation={navigation}
            disabled={!input.id}
          />
        </Col>
        {user.role == 'admin' ? (
          <>
            {buttons.map((button, index) => (
              <Col xs={12} md={4} key={index}>
                {button}
              </Col>
            ))}
          </>
        ) : user.role == 'customer admin' ? (
          <>
            {buttons.map((button, index) => (
              <Col xs={12} md={4} key={index}>
                {button}
              </Col>
            ))}
          </>
        ) : user.role == 'Customer Admin' ? (
          <>
            {buttons.map((button, index) => (
              <Col xs={12} md={4} key={index}>
                {button}
              </Col>
            ))}
          </>
        ) : null}
      </Row>
    </Card>
  );
}

export default EditTenantForm;
